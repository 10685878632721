<template>
    <main>
      <div
        class="modal fade"
        id="modalTipoHistorias"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Registro de Tipos de Hisorias
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card-waves ">
                <div class="card-header bg-light text-dark"><i class="fa fa-book fa-fw text-primary"></i>Datos</div>
                <div class="card-body">
                  <div class="row">

                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                      <label class="form-label">Codigo</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="tipos.codigo"
                        :class="{
                          'is-invalid':
                            $v.tipos.codigo.$error && $v.tipos.codigo.$dirty,
                        }"
                      />
                    </div>

                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                        <label class="form-label">Nombre</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="tipos.nombre"
                          :class="{
                            'is-invalid':
                              $v.tipos.nombre.$error && $v.tipos.nombre.$dirty,
                          }"
                        />
                      </div>

                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                      <label class="form-label">Path</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="tipos.path"
                        :class="{
                          'is-invalid':
                            $v.tipos.path.$error && $v.tipos.path.$dirty,
                        }"
                      />
                    </div>

                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                      <label class="form-label">Tipo de edad inicio</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="tipos.tipo_edad_inicio"
                        :class="{
                          'is-invalid':
                            $v.tipos.tipo_edad_inicio.$error && $v.tipos.tipos_edad_inicio.$dirty,
                        }"
                      >
                        <option value="A">A</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                      </select>
                    </div>

                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">Inicio de edad</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="tipos.edad_inicio"
                          :class="{
                            'is-invalid':
                              $v.tipos.edad_inicio.$error && $v.tipos.edad_inicio.$dirty,
                          }"
                        />
                    </div>

                        <div class="form-group col-lg-4 col-md-4 col-sm-12">
                            <label class="form-label">Tipo de edad final</label>
                            <select
                          class="form-control form-control-sm"
                          v-model="tipos.tipo_edad_final"
                          :class="{
                            'is-invalid':
                              $v.tipos.tipo_edad_final.$error && $v.tipos.tipo_edad_final.$dirty,
                          }"
                        >
                          <option value="A">A</option>
                          <option value="M">M</option>
                          <option value="D">D</option>
                            </select>
                        </div>
  
                        <div class="form-group col-lg-4 col-md-4 col-sm-12">
                          <label class="form-label">Final de edad</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="tipos.edad_final"
                            :class="{
                              'is-invalid':
                                $v.tipos.edad_final.$error && $v.tipos.edad_final.$dirty,
                            }"
                          />
                        </div>
                
                    

                      <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">Sexo</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="tipos.sexo"
                          :class="{
                            'is-invalid':
                              $v.tipos.sexo.$error && $v.tipos.sexo.$dirty,
                          }"
                        >
                          <option value="A">A</option>
                          <option value="F">F</option>
                          <option value="M">M</option>
                        </select>
                        </div>
                </div>
              </div>
              
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="save()"
              >
                Guardar<i class="fa fa-save fa-fw"></i>
              </button>
              <button
                type="button"
                class="btn btn-light btn-sm"
                @click="cerrar()"
              >
                Cerrar<i class="fa fa-times-circle fa-fw"></i>
              </button>

            </div>
            </div>
            </div>
          </div>
        </div>

    </main>
  </template>
  <script>

  import tipoHistoriaService from "@/services/tipoHistoriaService";
  import Toast from "../../../../components/common/utilities/toast";
  import { required } from "vuelidate/lib/validators";
  import $ from "jquery";
  
  export default {
    data() {
      return {
        tipos: {
          id:"",
          codigo:"",
          nombre:"",
          tipo_edad_inicio:"",
          edad_inicio:"",
          tipo_edad_final:"",
          edad_final:"",
          path:"",
          sexo:""
        },
        type: "store",
      };
    },
    validations() {
      return {
        tipos: {
          codigo:{required},
          nombre:{required},
          tipo_edad_inicio:{required},
          edad_inicio:{required},
          tipo_edad_final:{required},
          edad_final:{required},
          path:{required},
          sexo:{required}
        },
        
      };
    },
    methods: {
      async save() {
        try {
          this.$v.tipos.$touch();
           
          if (this.$v.tipos.$invalid) {
            return;
          }

          this.LoaderSpinnerShow();

          if (this.type === "store") {
            await tipoHistoriaService.store(this.tipos);
            this.type = "store";
            this.limpiarCampos();
          } else if (this.type === "edit") {
            await tipoHistoriaService.update(this.tipos);
            this.limpiarCampos();
            this.type = "store";
          }
  
          this.LoaderSpinnerHide();
  
          Toast.fire({
            icon: "success",
            title: "Datos guardados con éxito",
          });

          this.$emit("cargar-tipos");
  
          $("#modalTipoHistorias").modal("hide");
          
        } catch (err) {
          console.error(err);
          this.LoaderSpinnerHide();
          Toast.fire({
            icon: "error",
            title: "Ocurrio un error al procesar la solicitud",
          });
        }
    },
    async limpiarCampos() {
      this.tipos.codigo = "";
      this.tipos.nombre = "";
      this.tipos.path = "";
      this.tipos.tipo_edad_inicio = "";
      this.tipos.edad_inicio = "";
      this.tipos.tipo_edad_final = "";
      this.tipos.edad_final = "";
      this.tipos.sexo = "";
    }, 
    cerrar() {
        this.limpiarCampos();
        $("#modalTipoHistorias").modal("hide");
    },
    async edit(id) {
        this.$nextTick(async () => {
          this.type = "edit";
          $("#modalTipoHistorias").modal("show");
          const response = await tipoHistoriaService.show(id);
          this.tipos.id = response.data.id;
          this.tipos.codigo = response.data.codigo;
          this.tipos.nombre = response.data.nombre;
          this.tipos.path = response.data.path;
          this.tipos.tipo_edad_inicio = response.data.tipo_edad_inicio;
          this.tipos.edad_inicio= response.data.edad_inicio;
          this.tipos.tipo_edad_final = response.data.tipo_edad_final;
          this.tipos.edad_final= response.data.edad_final;
          this.tipos.sexo = response.data.sexo; 
        });
      },
    },
  };
  </script>